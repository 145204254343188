import { keccak256 } from 'js-sha3'

const ERC721Methods = {
    tokenURI: '0xc87b56dd',
    ownerOf: '0x6352211e',
    name: '0x06fdde03',
    symbol: '0x95d89b41',
    balanceOf: '0x70a08231',
    tokenOfOwnerByIndex: '0x2f745c59',
}

const ERC20Methods = {
    totalSupply: '0x18160ddd',
    balanceOf: '0x70a08231',
    transfer: '0xa9059cbb',
    allowance: '0xdd62ed3e',
    name: '0x06fdde03',
    symbol: '0x95d89b41',
    decimals: '0x313ce567',
}

const MulticallMethods = {
    aggregate: '0x252dba42',
    tryAggregate: '0xbce38bd7',
    getBlockNumber: '0x42cbb15c',
    getEthBalance: '0x4d2301cc',
}

const ENSResolverMethods = {
    getNames: '0xcbf8b66c',
}

export function toChecksumAddress(address, chainId = null) {
    if (typeof address !== 'string') {
        return ''
    }
    if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
        throw new Error(`Given address "${address}" is not a valid Ethereum address.`)
    }
    const stripAddress = address.replace('0x', '').toLowerCase()
    const prefix = chainId != null ? chainId.toString() + '0x' : ''
    const keccakHash = keccak256(prefix + stripAddress).replace(/^0x/i, '')
    let checksumAddress = '0x'

    for (let i = 0; i < stripAddress.length; i++) {
        checksumAddress +=
            parseInt(keccakHash[i], 16) >= 8 ? stripAddress[i].toUpperCase() : stripAddress[i]
    }

    return checksumAddress
}

console.log(toChecksumAddress('0x007EA5C0Ea75a8DF45D288a4debdD5bb633F9e56'.toLowerCase()))

function addressList(list) {
    return (
        toHex(32) +
        toHex(list.length) +
        list.map(k => k.replace('0x', '').padStart(64, '0')).join('')
    )
}

function parseStringList(data) {
    return parseList(data.slice(64)).map(offset =>
        _parseString(data.slice(128 + 2 * parseInt(offset, 16)))
    )
}

function parseBytes(data) {
    const numBytes = parseInt(data.slice(0, 32 * 2), 16)
    return data.slice(32 * 2, 32 * 2 + numBytes * 2)
}

export function parseString(data) {
    return _parseString(data.slice(64))
}
function _parseString(data) {
    return parseBytes(data).replace(/../g, k => String.fromCharCode(parseInt(k, 16)))
}

function parseList(data) {
    const numItems = parseInt(data.slice(0, 64), 16)
    const list = []
    for (let i = 0; i < numItems; i++) list.push(data.slice(64 + i * 64, 64 + (i + 1) * 64))
    return list
}

function toHex(number) {
    return BigInt(number).toString(16).padStart(64, '0')
}

function parseNum(data) {
    return parseInt(data, 16)
}

async function runMultiCalls(network, calls) {
    let header = toHex(0) + toHex(32 + 32) + toHex(calls.length)
    let body = ''
    let offsetBase = calls.length * 32
    for (let [target, callData] of calls) {
        let data = callData.slice(2)
        let packet =
            target.slice(2).padStart(64, '0') + // target address
            toHex(64) +
            toHex(data.length / 2) +
            data.padEnd(64 * Math.ceil(data.length / 64), '0')
        header += toHex(offsetBase + body.length / 2)
        body += packet
    }

    let data = await ethRPC(
        network,
        'eth_call',
        {
            to: network.multicall2,
            data: MulticallMethods.tryAggregate + header + body,
        },
        'latest'
    )
    let result = data.slice(2)
    let resultCount = parseInt(result.slice(64, 128), 16)
    // console.log(result.slice(64, 128))
    let results = []
    for (let i = 0; i < resultCount; i++) {
        let offset = parseInt(result.slice(128 + 64 * i, 128 + 64 * i + 64), 16)
        let success = parseInt(result.slice(128 + offset * 2, 128 + offset * 2 + 64), 16)
        let length = parseInt(result.slice(128 + 128 + offset * 2, 128 + 128 + offset * 2 + 64), 16)
        let data = result.slice(128 + 64 * 3 + offset * 2, 128 + 64 * 3 + offset * 2 + length * 2)
        results.push([!!success, data])
    }
    return results
}

async function cachedFetch(url) {
    const version = 1
    try {
        const { data, time, v } = JSON.parse(localStorage[url])
        if (v !== version || !time || Date.now() - time >= 10 * 60 * 1000) throw 'too old'
        return data
    } catch (err) {
        return fetch(url)
            .then(k => k.json())
            .then(data => {
                localStorage[url] = JSON.stringify({ data, time: Date.now(), v: version })
                return data
            })
    }
}

function ethRPC(network, method, ...params) {
    return fetch(network.rpcURL, {
        method: 'POST',
        headers: network.requireJSON
            ? {
                  'Content-Type': 'application/json',
              }
            : {},
        body: JSON.stringify({
            jsonrpc: '2.0',
            id: 1,
            // id: Math.round(10000 * Math.random()),
            method: method,
            params: params,
        }),
    })
        .then(k => k.json())
        .then(k => {
            // if(k.error) throw k.error
            if (k.error) throw new Error(k.error.message)
            return k.result
        })
}

// Transfer (index_topic_1 address from, index_topic_2 address to, uint256 value)
const TransferTopicSignature = '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'

export async function fetchTokensAndNFTs(network, account) {
    let logs = []
    if (network.maxLogRange) {
        const etherscanRequest = cachedFetch(
            network.etherscanAPI +
                '?' +
                new URLSearchParams({
                    module: 'logs',
                    action: 'getLogs',
                    fromBlock: '0',
                    toBlock: 'latest',
                    topic0: TransferTopicSignature,
                    topic2: '0x' + account.slice(2).padStart(64, '0'),
                    topic0_2_opr: 'and',
                    apikey: network.apikey,
                }).toString()
        )
        const getBlockNumberRequest = ethRPC(network, 'eth_blockNumber').then(data =>
            ethRPC(network, 'eth_getLogs', {
                fromBlock:
                    '0x' +
                    Math.max(0, parseInt(data.slice(2), 16) - network.maxLogRange).toString(16),
                toBlock: 'latest',
                topics: [TransferTopicSignature, null, '0x' + account.slice(2).padStart(64, '0')],
            })
        )
        logs = [...(await etherscanRequest).result, ...(await getBlockNumberRequest)]
    } else {
        logs = await ethRPC(network, 'eth_getLogs', {
            fromBlock: '0x1',
            toBlock: 'latest',
            topics: [TransferTopicSignature, null, '0x' + account.slice(2).padStart(64, '0')],
        })
    }
    logs = logs.slice(-100)

    console.log(logs)
    const calls = []
    calls.push(
        network.multicall2 + MulticallMethods.getEthBalance + account.slice(2).padStart(64, '0')
    )
    if (network.defaultTokens) {
        for (let tok of network.defaultTokens) {
            logs.push({
                address: tok,
                topics: [
                    TransferTopicSignature,
                    '0x' + account.slice(2).padStart(64, '0'),
                    '0x' + account.slice(2).padStart(64, '0'),
                ],
            })
        }
    }
    for (let log of logs) {
        calls.push(log.address + ERC20Methods.totalSupply)
        calls.push(log.address + ERC20Methods.decimals)
        calls.push(log.address + ERC20Methods.name)
        calls.push(log.address + ERC20Methods.symbol)
        calls.push(log.address + ERC20Methods.balanceOf + account.slice(2).padStart(64, '0'))
        // for NFTs, the tokenId is also part of the indexed event
        if (log.topics[3]) {
            calls.push(log.address + ERC721Methods.ownerOf + log.topics[3].slice(2))
            calls.push(log.address + ERC721Methods.tokenURI + log.topics[3].slice(2))
        }
    }

    const map = await multicallMap(network, calls)

    return {
        logs: logs,
        results: map,
    }
}

export async function getRecentTransactions(network) {
    const blockNumber = await ethRPC(network, 'eth_blockNumber')
    const numRecentBlocks = 10
    const recentLogs = await ethRPC(network, 'eth_getLogs', {
        fromBlock:
            '0x' + Math.max(0, parseInt(blockNumber.slice(2), 16) - numRecentBlocks).toString(16),
        toBlock: 'latest',
        topics: [TransferTopicSignature],
    })
    return recentLogs
}

async function multicallMap(network, calls) {
    const callTuples = Array.from(new Set(calls)).map(k => [k.slice(0, 42), k.slice(42)])
    const results = await runMultiCalls(network, callTuples)
    const map = {}
    for (let i = 0; i < callTuples.length; i++) map[callTuples[i].join('')] = results[i][1]
    return map
}

export async function getRecentTransfers(network) {
    const allTxs = await getRecentTransactions(network)
    const txs = allTxs
        .filter(
            k =>
                k.topics.length === 4 &&
                !k.topics[1].endsWith('000') &&
                !k.topics[2].endsWith('000') &&
                k.topics[3]
        )
        .slice(-30)
    const calls = []
    for (let tx of txs) {
        calls.push(tx.address + ERC20Methods.decimals)
        calls.push(tx.address + ERC20Methods.name)
        calls.push(tx.address + ERC20Methods.symbol)
        if (tx.topics[3]) {
            calls.push(tx.address + ERC721Methods.tokenURI + tx.topics[3].slice(2))
        }
    }
    // calls.push([
    //     network.ensreverse,
    //     ENSResolverMethods.getNames +
    //         addressList([
    //             '0xffd1ac3e8818adcbe5c597ea076e8d3210b45df5',
    //             '0x18ae9fc06bed0637b1d46063d6b7af1a4f97b02c',
    //         ]),
    // ])
    const map = await multicallMap(network, calls)
    return txs.map(tx => {
        const addr = tx.address
        return {
            from: '0x' + tx.topics[1].slice(-40),
            to: '0x' + tx.topics[2].slice(-40),

            token: {
                name: parseString(map[addr + ERC20Methods.name]),
                decimals: parseNum(map[addr + ERC20Methods.decimals]),
                symbol: parseString(map[addr + ERC20Methods.symbol]),
                URI:
                    tx.topics[3] &&
                    parseString(map[addr + ERC721Methods.tokenURI + tx.topics[3].slice(2)]),
            },
        }
    })
}
